import React, { useState } from "react";
import * as S from "./Nav.styles";
import PropTypes from "prop-types";
import Logo from "../../assets/LogoNew.png";
import { useNavigate } from "react-router-dom";

const Nav = () => {
  const [scrolled, setScrolled] = useState(false);

  const email = localStorage.getItem("email");

  const navigate = useNavigate();
  return (
    <S.Nav
      scrolled={scrolled}
      onScroll={() => setScrolled(!scrolled)}
      className="navbar scrolled"
    >
      <div className="navbarWrapper">
        <div className="navmenu">
          <S.NavBrand>
            <img src={Logo} alt="LTU Basketball" />
          </S.NavBrand>
        </div>
        {localStorage.getItem("token") ? (
          <div className="loggedin">
            <p>Logged in as: {email}</p>
            <button
              className="button is-link"
              onClick={() => {
                localStorage.removeItem("token");
                localStorage.removeItem("email");
                navigate("/");
              }}
            >
              Logout
            </button>
          </div>
        ) : null}
      </div>
    </S.Nav>
  );
};

Nav.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      active: PropTypes.func,
    })
  ),
};

export default Nav;
